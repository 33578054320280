import axios from "axios";
import Swal from "sweetalert2";
// import funciones from "./../../mixins/funciones";
const state = {
  listTotalCotizacion: [],
  listUser: [],
  cboStatus: [],
  datos: null,
  datosresumen: null,
  listTotalCotizacion: [],
};
const mutations = {
  SET_TOTAL_COTIZACION(state, data) {
    state.listTotalCotizacion = data;
  },
  SET_SUCURSAL(state, data) {
    state.listSucursal = data;
  },
  setnavieraCalc(state, data) {
    state.navieraCalc = data;
  },
  setcontendedorCalc(state, data) {
    state.contendedorCalc = data;
  },
  SET_LIST_USER(state, data) {
    state.listUser = data;
  },
  SET_CBO_STATUS(state, data) {
    state.cboStatus = data;
  },
  SET_DATOS(state, data) {
    state.datos = data;
  },
  SET_DATOS_RESUMEN(state, data) {
    state.datosresumen = data;
  },
};

const actions = {
  async GetTotalCotizacion({ commit }) {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "get_total_cotizacion",
      headers: headers,
      data: {
        iso_pais: JSON.parse(sessionStorage.getItem("iso_pais")),
      },
    };

    await axios(config).then((response) => {
      commit("SET_TOTAL_COTIZACION", response.data.data);
    });
  },
  async CargarPuertoPorTipoPorPais(__, data) {
    var vm = this;
    vm.state.overlay = true;
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `CargarPuertoXPaisXTipo/${data.id_pais}/${data.tipo}`,
      headers: headers,
    };
    let valores = await axios(config);
    if (!valores.data.estadoflag) {
      Swal.fire({
        icon: "info",
        title: valores.data.mensaje,
      });
      return [];
    }
    vm.state.overlay = false;
    return valores.data.data;
  },
  async CargarnavieraCalc({ commit }) {
    var vm = this;
    vm.state.overlay = true;
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + `cargar_navieras`,
      headers: headers,
    };
    let valores = await axios(config);
    if (valores.data.estadoflag) {
      commit("setnavieraCalc", valores.data.data);
    } else {
      Swal.fire({
        icon: "info",
        title: valores.data.mensaje,
      });
    }
    vm.state.overlay = false;
  },
  async CargarcontendedorCalc({ commit }) {
    var vm = this;
    vm.state.overlay = true;
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + `cargar_contenedores`,
      headers: headers,
    };
    let valores = await axios(config);
    if (valores.data.estadoflag) {
      commit("setcontendedorCalc", valores.data.data);
    } else {
      Swal.fire({
        icon: "info",
        title: valores.data.mensaje,
      });
    }
    vm.state.overlay = false;
  },
  async CargarSucursal({ commit }) {
    let vm = this;
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + `cargar_sucursal`,
      headers: headers,
    };
    await axios(config).then((response) => {
      let data = response.data;
      if (data.estadoflag) {
        commit("SET_SUCURSAL", data.data);
      }
    });
  },
  async getListUserCalc({ commit }) {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "listar_usuarios_calculadora",
      headers: headers,
      data: {
        iso_pais: JSON.parse(sessionStorage.getItem("iso_pais")),
      },
    };
    let response = await axios(config);

    commit("SET_LIST_USER", response.data.data);
  },
  async getCboStatus({ commit }) {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "cargue_status",
      headers: headers,
    };
    let response = await axios(config);

    commit("SET_CBO_STATUS", response.data.data);
  },
  async SetCall(__, data) {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "insert_call",
      headers: headers,
      data: data,
    };
    await axios(config).then((response) => {
      Swal.fire({
        icon: response.data.data[0].tipomensaje == "TMSGINF" ? "info" : "error",
        title: response.data.data[0].mensaje,
      });
    });
  },
  async ExportarListUser() {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "blob",
    };

    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        "export_listar_usuarios_calculadora?id_pais=" +
        JSON.parse(sessionStorage.getItem("iso_pais")),
      headers: headers,
      responseType: "blob",
      data: {
        iso_pais: JSON.parse(sessionStorage.getItem("iso_pais")),
      },
    };
    await axios(config).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Listado Usuario Calculadora - ${Date.now()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    });
  },

  async GetCotFCL({ commit }, token) {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };

    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + "get_cot_fcl?token=" + token,
      headers: headers,
    };
    let response = await axios(config);

    commit("SET_DATOS", response.data.data);
  },
  async GetCotLCL({ commit }, token) {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };

    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + "get_cot_lcl?token=" + token,
      headers: headers,
    };
    let response = await axios(config);

    commit("SET_DATOS", response.data.data);
  },
  async GetCotAereo({ commit }, token) {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };

    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + "get_cot_aereo?token=" + token,
      headers: headers,
    };
    let response = await axios(config);

    commit("SET_DATOS", response.data.data);
  },

  async GetCotFCLResumen({ commit }, token) {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };

    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + "get_cot_fcl_resumen?token=" + token,
      headers: headers,
    };
    let response = await axios(config);

    commit("SET_DATOS_RESUMEN", response.data.data);
  },
  async GetCotLCLResumen({ commit }, token) {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };

    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + "get_cot_lcl_resumen?token=" + token,
      headers: headers,
    };
    let response = await axios(config);

    commit("SET_DATOS_RESUMEN", response.data.data);
  },
  async GetCotAereoResumen({ commit }, token) {
    var headers = {
      "auth-token": sessionStorage.getItem("auth-token"),
      "Content-Type": "application/json",
    };

    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN + "get_cot_aereo_resumen?token=" + token,
      headers: headers,
    };
    let response = await axios(config);

    commit("SET_DATOS_RESUMEN", response.data.data);
  },
};

export default {
  namespace: true,
  state,
  mutations,
  actions,
};
